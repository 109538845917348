import React from 'react';
import { IconButton, Spinner, useTheme } from '@zydalabs/storefront-components';
import { Typography } from '@zydalabs/zac-react';
import { useIsMobile } from 'common/hooks';
import { localizeNumber } from 'common/utils';
import useTranslation from 'next-translate/useTranslation';
import { IcMinus, IcPlus } from '../../../../common/icons';
import { StyledCount, StyledIcon, StyledCountNumber } from './styles';
import { COUNTER_TYPE } from '../../../../common/constants/counterTypes';

export type CounterVariantType = 'default' | 'circled' | 'multipleAddOn';

type CounterType = {
  count: number;
  onChange: (number) => void;
  maxCount?: number;
  minCount?: number;
  disabled?: boolean;
  isLoading?: boolean;
  disableIncrement?: boolean;
  variant?: CounterVariantType;
  margin?: string;
  onIncrement?: () => void;
  onDecrement?: () => void;
  stepBy?: number;
};

const Counter: React.FC<CounterType> = ({
  count,
  onChange,
  maxCount,
  minCount,
  disableIncrement,
  variant = COUNTER_TYPE.DEFAULT,
  margin,
  onDecrement,
  onIncrement,
  disabled,
  isLoading,
  stepBy = 1,
}) => {
  const theme: any = useTheme();
  const isMobile = useIsMobile();
  const { lang } = useTranslation();
  const iconColor = {
    [COUNTER_TYPE.DEFAULT]: theme.color.headlineText,
    [COUNTER_TYPE.MULTIADDON]: count > 0 ? theme.color.white : theme.color.disabled,
    [COUNTER_TYPE.CIRCLED]: theme.color.primary,
  };

  const TextMinWidth = {
    [COUNTER_TYPE.DEFAULT]: '40px',
    [COUNTER_TYPE.MULTIADDON]: '32px',
    [COUNTER_TYPE.CIRCLED]: '54px',
  };

  const isCountEqualsZero = count === 0;
  const isDecrementDisabled = disabled || isCountEqualsZero || (minCount && count === minCount);
  const isIncrementDisabled = disabled || disableIncrement || maxCount === 0 || (maxCount && count >= maxCount);

  const decrementCount = () => {
    if (onDecrement) onDecrement();
    return onChange(count - stepBy);
  };

  const incrementCount = () => {
    if (onIncrement) onIncrement();
    return onChange(count + stepBy);
  };

  const iconSize = isMobile ? `${theme.space[6]}px` : `${theme.space[5]}px`;

  return (
    <StyledCount margin={margin} iconSize={iconSize}>
      <StyledIcon count={count} variant={variant} disabled={isDecrementDisabled} hide={isCountEqualsZero} iconSize={iconSize}>
        <IconButton
          onClick={decrementCount}
          icon={IcMinus}
          width={iconSize}
          height={iconSize}
          color={isCountEqualsZero ? theme.color.disabled : iconColor[variant]}
          testId="decrease-count"
          disabled={isDecrementDisabled}
          accessibilityLabel="decrease count"
        />
      </StyledIcon>
      <StyledCountNumber
        hide={variant === COUNTER_TYPE.MULTIADDON ? isCountEqualsZero : undefined}
        TextMinWidth={TextMinWidth[variant]}
      >
        {isLoading ? (
          <Spinner radius={theme.space[3]} color={theme.color.grey0} />
        ) : (
          <Typography
            testId="current-count"
            variant={variant === COUNTER_TYPE.CIRCLED ? 'heading18' : 'body14'}
            isDisabled={disabled}
          >
            {localizeNumber(lang, count)}
          </Typography>
        )}
      </StyledCountNumber>
      <StyledIcon count={count} variant={variant} disabled={isIncrementDisabled} iconSize={iconSize}>
        <IconButton
          onClick={incrementCount}
          icon={IcPlus}
          width={iconSize}
          height={iconSize}
          color={iconColor[variant]}
          disabled={isIncrementDisabled}
          testId="increase-count"
          accessibilityLabel="increase count"
        />
      </StyledIcon>
    </StyledCount>
  );
};

export default Counter;
