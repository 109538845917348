import React, { ReactChild } from 'react';

const IcPlus = (): ReactChild => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71429 12C1.71429 6.3191 6.3191 1.71429 12 1.71429C17.6809 1.71429 22.2857 6.3191 22.2857 12C22.2857 17.6809 17.6809 22.2857 12 22.2857C6.3191 22.2857 1.71429 17.6809 1.71429 12ZM12 0C5.37233 0 0 5.37233 0 12C0 18.6277 5.37233 24 12 24C18.6277 24 24 18.6277 24 12C24 5.37233 18.6277 0 12 0ZM11.1429 18V12.8571H6V11.1429H11.1429V6H12.8571V11.1429H18V12.8571H12.8571V18H11.1429Z"
      fill="#1F1F1F"
    />
  </svg>
);

export default IcPlus;
