import { getDurationTimeText } from 'modules/locationsModule/screens/EstimatedTimePage/utils';

export const trimText = (text: string, number: number): string =>
  text && text.length > number ? `${text.substring(0, number)} ...` : text;

export const getPrepTimeText = (prepTime, lang) => {
  const prepTimeStartText = { en: 'Prep time:', ar: 'وقت التحضير' };
  return getDurationTimeText(prepTimeStartText, prepTime, true)[lang];
};

/**
 * This function processes duplicated product or category titles to ensure that each
 * product or category has a unique title, even when they have different prices.
 * It accepts a title string in Arabic or English for a product or category.
 * @param {string} title - The title of the product or category.
 * @returns {string} - A filtered string without the "BID-" prefix.
 */
export const sanitizeTitleString = (title: string): string => {
  const filteredTitle = title.includes('BID-') ? title.split('BID-') : [title];
  return filteredTitle[0];
};
