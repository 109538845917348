import ServiceConfigs from 'service/config';
import { cache, mutate } from 'swr';

import { useQuery } from 'service/utils';
import { storeProductDetailsQuery } from 'service/queries/products';
import { Product, Scalars } from 'service/types/generated';
import { QueryHookResponse } from 'service/types/internal';

type useProductDetailsType = ({
  uuid,
  branchId,
  initialData,
  shouldInvalidate,
  scheduledTime,
}: {
  uuid: string | null;
  branchId?: Scalars['ID'];
  initialData?: Product;
  shouldInvalidate?: boolean;
  scheduledTime?: string;
}) => Partial<QueryHookResponse<Product>>;

const useProductDetails: useProductDetailsType = ({ uuid, branchId, shouldInvalidate = false, scheduledTime, initialData }) => {
  const productDetailsQueryHookResponse = useQuery<Product>({
    query: storeProductDetailsQuery,
    variables:
      uuid && (shouldInvalidate || !cache.has(uuid))
        ? { subdomain: ServiceConfigs.getSubDomain(), uuid, branchId, ...(scheduledTime && { scheduledTime }) }
        : null,
    handler: (res: any) => {
      const { product } = res || {};
      cache.set(uuid, product);
      return product;
    },
    config: { initialData },
  });
  if (!shouldInvalidate && cache.has(uuid)) {
    const productDetails: Product = cache.get(uuid);
    const mutateProductDetailsCache = (data: Product) => mutate(uuid, data);
    return { data: productDetails, isLoading: false, mutate: mutateProductDetailsCache };
  }
  return productDetailsQueryHookResponse;
};

export default useProductDetails;
