import { gql } from 'graphql-request';

const fragmentProduct = gql`
  fragment returnedProduct on Product {
    uuid
    titleEn
    titleAr
    photoUrl
    maxPrepTime
    descriptionAr
    descriptionEn
    displayPrice
    fulfillmentMode
    highestDiscountedPercentage
    price
    variantsTitleEn
    variantsTitleAr
    specialNotesEnabled
    specialNotesRequired
    specialNotesPlaceholderAr
    specialNotesPlaceholderEn
    quickAddToCart
    publishedBranchIds
    categoryIds
    stockData(scheduledTime: $scheduledTime) {
      unavailable
      stockCount
      trackingType
    }
    variants {
      id
      titleAr
      titleEn
      price
      prepTime
      discountEnabled
      discountedPrice
      maximumQuantity
      images {
        photoUrl
      }
      stockData(scheduledTime: $scheduledTime) {
        unavailable
        stockCount
        trackingType
      }
      optionGroups {
        maxQuantity
        minQuantity
        position
        uuid
        multipleAddOnsEnabled
        titleAr
        titleEn
        bulkQuantityEnabled
        stepBy
        options {
          titleAr
          titleEn
          uuid
          price
        }
      }
    }
  }
`;

export default fragmentProduct;
