import { gql } from 'graphql-request';
import fragmentProduct from 'service/fragments/productFragment';

const storeProductDetailsQuery = gql`
  query ($subdomain: String!, $uuid: String!, $branchId: ID, $scheduledTime: ISO8601DateTime) {
    product(subdomain: $subdomain, uuid: $uuid, branchId: $branchId) {
      ...returnedProduct
    }
  }
  ${fragmentProduct}
`;

export default storeProductDetailsQuery;
