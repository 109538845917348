import styled, { css } from 'styled-components';
import { COUNTER_TYPE } from 'common/constants/counterTypes';

export const StyledCount = styled.div<{ margin: string; iconSize: boolean }>`
  ${({ iconSize }) => `
  height: ${iconSize};
  `};
  margin: ${props => props.margin};
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div<{ count: number; variant: string; disabled?: boolean; hide?: boolean; iconSize: boolean }>`
  ${({ iconSize }) => `
    width: ${iconSize};
    height: ${iconSize};
    & button {
      height: ${iconSize};
    }
  `};

  ${props =>
    props.variant === COUNTER_TYPE.MULTIADDON &&
    css`
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      ${props.hide && 'display: none'};
    `}
  ${props =>
    props.variant === COUNTER_TYPE.CIRCLED &&
    css`
      & button {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid ${props.disabled ? props.theme.color.disabled : props.theme.color.primary};
        justify-content: center;
        align-items: center;
      }
      & svg {
        display: block;
        width: 10px;
      }
    `}
`;

export const StyledCountNumber = styled.div<{ hide: boolean; TextMinWidth: string }>`
  min-width: ${props => props.TextMinWidth};
  display: flex;
  justify-content: center;
  ${props => props.hide && 'display: none'};
`;
