import { useTheme } from '@zydalabs/storefront-components';
import { useEffect, useState } from 'react';
import { useResponsive } from './responsive';

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const isMobileState = useResponsive(`max-width: ${theme.screenBreakpoints.large}`);

  useEffect(() => {
    setIsMobile(isMobileState);
  }, [isMobileState]);

  return isMobile;
};

export default useIsMobile;
