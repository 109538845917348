import { stockThresholdSettingsQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import { Setting } from '../../types/generated';
import { useQuery } from '../../utils';

type useStockThresholdSettingsType = ({
  initialData,
}?: {
  initialData?: Partial<Setting>;
}) => QueryHookResponse<Partial<Setting>>;

const useStockThresholdSettings: useStockThresholdSettingsType = ({ initialData } = {}) =>
  useQuery<Partial<Setting>>({
    query: stockThresholdSettingsQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain() },
    handler: (res: any) => res?.store?.setting,
    config: { initialData },
  });

export default useStockThresholdSettings;
