import { gql } from 'graphql-request';
import fragmentCartMutationResponse from 'service/fragments/cartFragmentMutationResponse';

const updateCartItemMutation = gql`
  mutation (
    $cartItemId: ID!
    $subdomain: String!
    $cartId: String!
    $menuItemId: String!
    $variantId: ID!
    $variantQuantity: Int!
    $options: [CartItemOptions!]
    $notes: String
    $scheduledTime: ISO8601DateTime
  ) {
    updateCartItem(
      cartItemId: $cartItemId
      subdomain: $subdomain
      cartId: $cartId
      menuItemId: $menuItemId
      variantId: $variantId
      variantQuantity: $variantQuantity
      options: $options
      notes: $notes
    ) {
      cart {
        ...returnedCart
      }
    }
  }
  ${fragmentCartMutationResponse}
`;

export default updateCartItemMutation;
